<template>
  <div class="auth-wrapper auth-v2">
    <div class="row auth-inner m-0">
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Brandstock</h2>
      </b-link>
      <div class="col col-lg-8 d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="require('@/assets/images/pages/login-v2.svg')" alt="Login V2"/>
        </div>
      </div>
      <div class="col col-lg-4 d-flex align-items-center auth-bg px-2 p-lg-5">
        <div class="col col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">Добро пожаловать в Brandstock! 👋</b-card-title>
          <!-- form -->
          <div class="auth-login-form mt-2">
            <div class="form-group">
              <label class="d-block">Электронная почта</label>
              <input
                  v-model="form.username"
                  placeholder="Введите почту"
                  type="email"
                  :class="['form-control', submitted && !form.username ? 'is-invalid' : null]"
              >
              <small v-if="submitted && !form.username" class="text-danger">Поле не может быть пустым</small>
            </div>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Пароль</label>
                <b-link :to="{ name:'auth-forgot-password' }">
                  <small>Забыл пароль?</small>
                </b-link>
              </div>
              <b-input-group
                  class="input-group-merge"
                  :class="['input-group input-group-merge', submitted && !form.password ? 'is-invalid' : null]"
              >
                <b-form-input
                    v-model="form.password"
                    autocomplete="off"
                    :class="['form-control', submitted && !form.password ? 'is-invalid' : null]"
                    placeholder="············"
                    :type="passwordFieldType"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                  </div>
                </div>
              </b-input-group>
              <small v-if="submitted && !form.password" class="text-danger">Поле не может быть пустым</small>
            </b-form-group>

            <!-- checkbox -->
            <div class="custom-control custom-checkbox">
              <input
                  id="status"
                  v-model="form.status"
                  type="checkbox"
                  :class="['custom-control-input', submitted && !form.status ? 'is-invalid' : null]"
              >
              <label class="custom-control-label" for="status">
                Запомнить меня
              </label>
            </div>

            <!-- submit buttons -->
            <b-button type="submit" class="mt-3" variant="primary" block :disabled="disabled" @click="login">
              Войти
            </b-button>
          </div>

          <b-card-text class="text-center mt-2">
            <span>Первый раз на нашей платформе? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Создать аккаунт</span>
            </b-link>
          </b-card-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  VBTooltip,
} from 'bootstrap-vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      form: {
        username: null,
        password: null,
      },
      disabled: false,
      submitted: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    async login() {
      try {
        const response = (await this.$api.auth.login(this.form)).data;
        localStorage.setItem('accessToken', response.token);
        localStorage.setItem('userData', JSON.stringify(response.userData));
        this.$ability.update(response.userData.ability);
        this.$router.replace({ name: 'remains' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Добро пожаловать ${response.userData.full_name || response.userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Вы успешно вошли в личный кабинет. Теперь вы можете начать исследовать!',
                },
              });
            });
      } catch (e) {
        // console.log(e.response.data);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
